import axios, { all } from 'axios';
import React, { useContext, useState, useEffect } from "react";
import { graphql } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha";

import { ModalContext } from "../components/layout";
import { Base, LimitMaxWidth } from "../components/layout";
import { EmployeeForm } from "../components/forms";
import SEO from '../components/seo';

import ArrowLeftWhite from "../assets/svg/arrow-left-white";
import ArrowRightWhite from "../assets/svg/arrow-right-white";
import Cross from "../assets/svg/cross";

import greenBackgroundPng from "../assets/images/background-green.png";
import greenBackgroundAvif from "../assets/images/background-green.avif";
import greenBackgroundWebp from "../assets/images/background-green.webp";

type Props = {
  data: any
}

export const Head = () => <SEO title="Syrona Health | Toolkits" />

const ToolkitsPage: React.FC<Props> = ({ data }) => <Base><ToolkitsContent data={data} /></Base>

export default ToolkitsPage;

export const query = graphql`
  query AllPrismicToolkit(
    $order: [SortOrderEnum]
    $fields: [PrismicToolkitFieldsEnum]
  ) {
    allPrismicToolkit(sort: { order: $order, fields: $fields }) {
      nodes {
        data {
          title {
            text
          }
          cover_image {
            url
          }
          description {
            text
          }
          toolkit {
            url
          }
          rank
        }
        uid
      }
    }
  }
`

const ToolkitsContent: React.FC<Props> = ({ data }) => {
  const allToolkits = data.allPrismicToolkit.nodes.sort((a, b) => {
    if (!a.data.rank && !b.data.rank) {
      return 0
    } else if (!a.data.rank) {
      return -1
    } else if (!b.data.rank) {
      return 1
    } else {
      return a.data.rank - b.data.rank
    }
  })
  
  return <>
    <div className="my-12">
      <LimitMaxWidth>
        <div className="mx-8">
          <LatestToolkitSection latestToolkit={allToolkits.at(0)}/>
          <Toolkits toolkits={allToolkits.slice(1)}/>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

type LTProps = {
  latestToolkit: any;
}

const LatestToolkitSection: React.FC<LTProps> = ({ latestToolkit }) => {
  const { setModal } = useContext(ModalContext);

  return <>
    <div className="py-12 flex max-md:flex-col md:space-x-8 max-md:space-y-8 md:items-center">
      <div className="md:w-6/12">
        <img src={latestToolkit.data.cover_image.url} alt={latestToolkit.data.title.text}/>
      </div>
      <div className="md:w-6/12 flex flex-col space-y-4">
        <div className="test-s2 font-nun text-syr-snd uppercase">Latest toolkit</div>
        <div className="text-h3 font-dm text-black">{`Read our ${latestToolkit.data.title.text}`}</div>
        <div className="text-b1 font-nun text-black">
          Support your workforce with guidance from experts in the field. These toolkits are designed for HR, Benefits teams and staff to raise awareness.
        </div>
        <button 
          className="px-8 py-2 w-48 bg-syr-snd rounded-md text-b1 font-nunsb text-white"
          onClick={() => setModal(<ToolkitModal toolkitUrl={latestToolkit.data.toolkit.url} toolkitName={latestToolkit.data.title.text}/>)}
        >
          Download
        </button>
      </div>
    </div>
  </>
}

type TProps = {
  toolkits: any;
}

const Toolkits: React.FC<TProps> = ({ toolkits }) => {
  const [displayedToolkits, setDisplayedToolkits] = useState(toolkits)
  const { setModal } = useContext(ModalContext);

  const moveBack = () => {
    setDisplayedToolkits([displayedToolkits[displayedToolkits.length - 1], ...displayedToolkits.slice(0, -1)]) 
  }

  const moveForward = () => {
    setDisplayedToolkits([...displayedToolkits.slice(1), displayedToolkits[0]]) 
  }

  return <>
    <div className="my-8 text-h3 font-dm text-black">Latest Resources</div>
    <div className='flex justify-center'>
      <div className="relative">
        <div className="absolute h-full -left-8 -top-20 flex justify-center items-center">
          <button
            className="w-16 h-16 rounded-lg bg-black flex justify-center items-center"
            onClick={moveBack}
          >
            <ArrowLeftWhite />
          </button>
        </div>
        <div className="absolute h-full -right-8 -top-20 flex justify-center items-center">
          <button
            className="w-16 h-16 rounded-lg bg-black flex justify-center items-center"
            onClick={moveForward}
          >
            <ArrowRightWhite />
          </button>
        </div>
        <div className="flex">
          {displayedToolkits.slice(0, 1).map((t, idx) => <>
            <div className={`sm:hidden ${idx>0 ? "ml-8 " : ""} w-64 h-96 flex flex-col justify-between`}>
              <div>
                <img className="aspect-square object-cover" src={t.data.cover_image.url} alt={t.data.title.text}/>
                <h4 className="text-s1 font-nunb text-black">{t.data.title.text}</h4>
                {/* <p className="text-b1 font-nun text-black">{t.data.description.text}</p> */}
              </div>
              <button 
                className="px-8 py-2 w-48 bg-syr-snd rounded-md text-b1 font-nunsb text-white"
                onClick={() => setModal(<ToolkitModal toolkitUrl={t.data.toolkit.url} toolkitName={t.data.title.text}/>)}
              >
                Download
              </button>
            </div>
          </>
          )}
          {displayedToolkits.slice(0, 2).map((t, idx) => <>
            <div className={`md:hidden max-sm:hidden ${idx>0 ? "ml-8" : ""} w-64 h-96 flex flex-col justify-between`}>
              <div>
                <img className="aspect-square object-cover" src={t.data.cover_image.url} alt={t.data.title.text}/>
                <h4 className="text-s1 font-nunb text-black">{t.data.title.text}</h4>
                {/* <p className="text-b1 font-nun text-black">{t.data.description.text}</p> */}
              </div>
              <button 
                className="px-8 py-2 w-48 bg-syr-snd rounded-md text-b1 font-nunsb text-white"
                onClick={() => setModal(<ToolkitModal toolkitUrl={t.data.toolkit.url} toolkitName={t.data.title.text}/>)}
              >
                Download
              </button>
            </div>
          </>
          )}
          {displayedToolkits.slice(0, 3).map((t, idx) => <>
            <div className={`lg:hidden max-md:hidden ${idx>0 ? "ml-8" : ""} w-64 h-96 flex flex-col justify-between`}>
              <div>
                <img className="aspect-square object-cover" src={t.data.cover_image.url} alt={t.data.title.text}/>
                <h4 className="text-s1 font-nunb text-black">{t.data.title.text}</h4>
                {/* <p className="text-b1 font-nun text-black">{t.data.description.text}</p> */}
              </div>
              <button 
                className="px-8 py-2 w-48 bg-syr-snd rounded-md text-b1 font-nunsb text-white"
                onClick={() => setModal(<ToolkitModal toolkitUrl={t.data.toolkit.url} toolkitName={t.data.title.text}/>)}
              >
                Download
              </button>
            </div>
          </>
          )}
          {displayedToolkits.slice(0, 4).map((t, idx) => <>
            <div className={`max-lg:hidden ${idx>0 ? "ml-8" : ""} w-64 h-96 flex flex-col justify-between`}>
              <div>
                <img className="aspect-square object-cover" src={t.data.cover_image.url} alt={t.data.title.text}/>
                <h4 className="text-s1 font-nunb text-black">{t.data.title.text}</h4>
                {/* <p className="text-b1 font-nun text-black">{t.data.description.text}</p> */}
              </div>
              <button 
                className="px-8 py-2 w-48 bg-syr-snd rounded-md text-b1 font-nunsb text-white"
                onClick={() => setModal(<ToolkitModal toolkitUrl={t.data.toolkit.url} toolkitName={t.data.title.text}/>)}
              >
                Download
              </button>
            </div>
          </>
          )}
        </div>
      </div>
    </div>
  </>
}

const ToolkitModal: React.FC<{toolkitUrl: string, toolkitName: string}> = ({toolkitUrl, toolkitName}) => {
  const { setModal } = useContext(ModalContext);
  const [captchaToken, setCaptchaToken] = useState('')
  const [showCaptchaNudge, setShowCaptchaNudge] = useState(false)
  const [showDownloadButton, setShowDownloadButton] = useState(false)
  const [loading, setLoading] = useState(false);

  const onSubmit = (values: {[key: string]: string}) => {
    const submit = async () => {
      if (captchaToken) {
        setLoading(true)
        let data = {
          full_name: values.name,
          email: values.email,
          company_name: values.company,
          job_title: values.title,
          ...(values.phone ? {phone_number: values.phone} : {}),
          company_size: parseInt(values.size),
          hear_about_us: values.hearAboutUs,
          context: `toolkit ${toolkitName}`,
          captcha_token: captchaToken,
        }
      await axios.post(
          `${process.env.GATSBY_API_URL}/v1/api/contacts/employee/`,
          data
        ).then(() => {
          setShowDownloadButton(true)
        }).catch(e => {
          console.log(e)
          return
        }).finally(() => setLoading(true))
      } else {
        setShowCaptchaNudge(true)
      }
    }

    submit()
  }

  const onCaptchaSuccess = (captchaToken: string) => {
    setCaptchaToken(captchaToken)
    setShowCaptchaNudge(false)
  }

  return <>
    <div className="bg-cover bg-center rounded-xl p-16 relative" style={{backgroundImage: `url(${greenBackgroundPng})` }}>
      <div className="flex flex-col justify-center items-center">
        <div className="absolute top-0 w-full flex justify-end">
          <button className="m-8" onClick={() => setModal(null)}><Cross/></button>
        </div>
        <h3 className="text-h3 font-dm text-white mb-0">Download toolkit</h3>
        {showDownloadButton ?
          <>
            <a href={toolkitUrl} download={true} target="_blank"><div className='my-8 bg-black text-btn font-nun text-white px-4 py-2 rounded-md'>Download toolkit</div></a>
          </> :
          <>
            <div className="text-b1 font-nun text-white mb-8">Please fill the required fields to download the resource</div>
            <EmployeeForm buttonText="Get toolkit" onSubmit={onSubmit} buttonColour='bg-black' loading={loading} />
            <div className="mt-8">
              <div className={`${showCaptchaNudge ? "" : "hidden"} text-sm font-nun text-red-400`}>Please complete captcha</div>
              <ReCAPTCHA
                className="rounded-md"
                sitekey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
                onChange={onCaptchaSuccess}
              />
            </div>
          </>
        }
      </div>
    </div>
  </>
}
