import React from "react";

const ArrowLeftWhite: React.FC = () => <>
  <svg width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M30 11.3945L3 11.3945" stroke="#FEFEFE" stroke-width="2" stroke-linecap="round"/>
  <path d="M12 21.3945L2 11.3945L12 1.39453" stroke="#FEFEFE" stroke-width="2" stroke-linecap="round"/>
  </svg>
</>

export default ArrowLeftWhite;
